@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

.rtf button {
  border: 0.01rem solid rgb(59 130 246);
  background-color: rgb(29 78 216);
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  height: 400px !important;
}

.image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
  height: calc(100vh - 100px) !important;
}

.image-gallery-thumbnail {
  background-color: white !important;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 50px !important;
  object-fit: contain;
}

.image-gallery-content.fullscreen .image-gallery-thumbnail-image {
  height: 80px !important;
}

textarea {
  field-sizing: "content";
  width: 100%;
  min-height: 1lh;
  max-height: 5lh;
}

svg.loader {
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

circle.loader {
  fill: none;
  stroke: hsl(214, 97%, 59%);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}
